import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import AddItems from './AddItems';
import EditItem from './EditItem';
import ShowItems from '../components/ShowItems';

function RecommendedItems() {  
  const [addItem, setAddItem] = useState(false);
  const [editItem, setEditItem] = useState(null); // New state for editing

  return (
    <div>
      <Helmet>
        <title>Recommended Items | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {addItem ? (
        <AddItems setAddItem={setAddItem} />
      ) : editItem ? (
        <EditItem itemId={editItem} setEditItem={setEditItem} /> // Pass the item ID to EditItem
      ) : (
        <div className=''>
          <div className='flex items-center justify-between'>
            <div>
              <h1 className="text-3xl font-bold mb-4">Recommended Items</h1>
              <p>Welcome to the Recommended Items!</p>
            </div>
            <button onClick={() => setAddItem(true)} className='text-white bg-red-400 hover:bg-red-500 p-2 md:px-5 rounded-lg'>
              Add Item
            </button>
          </div>
          <ShowItems setEditItem={setEditItem} /> {/* Pass setEditItem to ShowItems */}
        </div>
      )}
    </div>
  );
}

export default RecommendedItems;
