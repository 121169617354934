import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';

const ShowItems = ({ setEditItem }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch items from the API
    const fetchItems = async () => {
      try {
        const response = await axios.get('https://api.jemputlah.com/api/show-items');
        setLoading(false);
        const sortedItems = response.data.sort((a, b) => b.id - a.id); // Adjust sorting logic if needed
        setItems(sortedItems);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, []);

  // Handle Delete item
  const handleDelete = async (id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this item?');
    if (!isConfirmed) {
      return; // Exit if the user cancels
    }
  
    try {
      await axios.delete(`https://api.jemputlah.com/api/delete-item/${id}`);
      setItems(items.filter(item => item.id !== id));
      console.log('Item deleted successfully');
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };
  

  // Handle Edit item (redirect to edit page, assuming an edit page exists)
  const handleEdit = (id) => {
    window.location.href = `/edit-item/${id}`;  // Adjust to your routing setup
  };

  return (
    <div className="container mx-auto mt-4">
      {loading ? (
          <div className="flex justify-center items-center h-64">
            <FaSpinner className="text-red-400 text-4xl animate-spin" />
          </div>
        ) : (
          <ul className="space-y-4">
            {items.map((item) => (
              <li key={item.id} className="bg-white shadow-md rounded-lg p-4 flex flex-col space-y-4 md:flex-row items-center justify-between">
                <div className="flex items-center justify-start w-full">
                  <img
                    src={`https://cdn.jemputlah.com/images/${item.image}`}
                    alt={item.title}
                    className="w-16 h-16 object-cover rounded-md mr-4"
                  />
                  <div>
                    <h2 className="text-xl font-semibold">{item.title}</h2>
                    <p className="text-gray-500 hidden">{item.link}</p>
                    <p className="text-lg font-bold text-red-400">RM {item.price}</p>
                  </div>
                </div>
                <div className="flex justify-between w-full md:w-1/3 space-x-2">
                <button
                  onClick={() => setEditItem(item.id)} // Set editItem with item ID
                  className="bg-blue-500 text-white px-4 py-2 w-full rounded-md hover:bg-blue-600"
                >
                  Edit
                </button>
                  <button
                    onClick={() => handleDelete(item.id)}
                    className="bg-red-500 text-white px-4 py-2 w-full rounded-md hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
    </div>
  );
};

export default ShowItems;
