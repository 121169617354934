import React, { useState } from 'react';
import ShowPromoCodes from './ShowPromoCodes';
import AddPromoCode from './AddPromoCode';
import EditPromoCode from './EditPromoCode';
import PromoCodeList from './PromoCodeList';

const PromoCodes = () => {
    const [email, setEmail] = useState(localStorage.getItem('userEmail'));
    const [view, setView] = useState('list'); // 'list', 'add', 'edit'
    const [editingPromo, setEditingPromo] = useState(null); // Track the promo code being edited
    const [reload, setReload] = useState(false); // Trigger reload after adding/editing

    const handleAddClick = () => setView('add');
    const handleEditClick = (promo) => {
        setEditingPromo(promo);
        setView('edit');
    };
    const handleBackToList = () => {
        setView('list');
        setEditingPromo(null);
    };
    const triggerReload = () => setReload(!reload);

    return (
        <div className="py-6">

            {view === 'list' && (
                <div>
                    <button
                        className="mb-4 bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded"
                        onClick={handleAddClick}
                    >
                        Add Promo Code
                    </button>
                    
                    {/* <PromoCodeList email={email} /> */}
                    <ShowPromoCodes onEdit={handleEditClick} reload={reload} email={email} />
                </div>
            )}

            {view === 'add' && (
                <div>
                    <button
                        className="mb-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                        onClick={handleBackToList}
                    >
                        Back to List
                    </button>
                    <AddPromoCode onReload={triggerReload} email={email} />
                </div>
            )}

            {view === 'edit' && (
                <div>
                    <button
                        className="mb-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                        onClick={handleBackToList}
                    >
                        Back to List
                    </button>
                    <EditPromoCode
                        promo={editingPromo}
                        onEditComplete={handleBackToList}
                        onReload={triggerReload}
                        email={email}
                    />
                </div>
            )}
        </div>
    );
};

export default PromoCodes;
