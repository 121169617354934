import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

function Settings() {
  const [settings, setSettings] = useState({
    price: '',
    maintenance_mode: false,
    sandbox: false,
    live_api: '',
    live_collection_id: '',
    live_x_signature: '',
    sandbox_api: '',
    sandbox_collection_id: '',
    sandbox_x_signature: ''
  });

  useEffect(() => {
    axios.get('https://api.jemputlah.com/api/settings')
      .then(response => setSettings(response.data))
      .catch(error => console.error('Error fetching settings:', error));
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put('https://api.jemputlah.com/api/settings/update', settings)
      .then(() => alert('Settings updated successfully'))
      .catch(error => console.error('Error updating settings:', error));
  };

  return (
    <div className="">
      <Helmet>
        <title>Settings | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className='space-y-8'>
        <div>
          <h1 className="text-3xl font-bold mb-4">Settings</h1>
        </div>
        <div className="max-w-3xl bg-white shadow-md rounded-lg p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="flex items-center space-x-4 justify-end">
              <label className="text-sm font-medium text-gray-700">Maintenance Mode</label>
              <button
                onClick={() =>
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    maintenance_mode: !prevSettings.maintenance_mode,
                  }))
                }
                className={`relative inline-flex items-center h-6 w-11 rounded-full transition-colors duration-200 ${
                  settings.maintenance_mode ? 'bg-red-400' : 'bg-gray-300'
                }`}
              >
                <span
                  className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ${
                    settings.maintenance_mode ? 'translate-x-5' : 'translate-x-1'
                  }`}
                />
              </button>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Price</label>
              <input
                type="number"
                name="price"
                value={settings.price}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-400 focus:border-red-400"
              />
            </div>
  
            <h2 className='text-xl font-semibold'>Billplz Configuration</h2>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Live API</label>
              <input
                type="text"
                name="live_api"
                value={settings.live_api}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-400 focus:border-red-400"
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Live Collection ID</label>
              <input
                type="text"
                name="live_collection_id"
                value={settings.live_collection_id}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-400 focus:border-red-400"
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Live X Signature</label>
              <input
                type="text"
                name="live_x_signature"
                value={settings.live_x_signature}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-400 focus:border-red-400"
              />
            </div>
  
            <div className="flex items-center space-x-4 mt-4">
              <label className="text-sm font-medium text-gray-700">Sandbox Mode</label>
              <button
                onClick={() =>
                  setSettings((prevSettings) => ({
                    ...prevSettings,
                    sandbox: !prevSettings.sandbox,
                  }))
                }
                className={`relative inline-flex items-center h-6 w-11 rounded-full transition-colors duration-200 ${
                  settings.sandbox ? 'bg-red-400' : 'bg-gray-300'
                }`}
              >
                <span
                  className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200 ${
                    settings.sandbox ? 'translate-x-5' : 'translate-x-1'
                  }`}
                />
              </button>
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Sandbox API</label>
              <input
                type="text"
                name="sandbox_api"
                value={settings.sandbox_api}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-400 focus:border-red-400"
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Sandbox Collection ID</label>
              <input
                type="text"
                name="sandbox_collection_id"
                value={settings.sandbox_collection_id}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-400 focus:border-red-400"
              />
            </div>
  
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Sandbox X Signature</label>
              <input
                type="text"
                name="sandbox_x_signature"
                value={settings.sandbox_x_signature}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-red-400 focus:border-red-400"
              />
            </div>
  
            <div className="pt-4">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-red-400 hover:bg-red-500 text-white font-semibold rounded-md shadow-md transition ease-in-out duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
              >
                Save Settings
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Settings;
