import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { FaEnvelope, FaWhatsapp, FaSpinner } from 'react-icons/fa';

function Sales() {
  const [payments, setPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Loading state
  const paymentsPerPage = 20;

  useEffect(() => {
    // Fetch payments data from API
    setLoading(true); // Start loading
    axios.get('https://api.jemputlah.com/api/payments')
      .then(response => {
        setPayments(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the payments data!", error);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }, []);

  useEffect(() => {
    // Scroll to top when currentPage changes
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  const formatDateMalaysia = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).replace(',', '');
  };

  // Remove duplicates based on payment_id and sort by recent
  const uniquePayments = Array.from(
    new Map(payments.map((item) => [item.payment_id, item]))
      .values()
  ).sort((a, b) => new Date(b.paid_at) - new Date(a.paid_at));

  // Pagination calculations
  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = uniquePayments.slice(indexOfFirstPayment, indexOfLastPayment);
  const totalPages = Math.ceil(uniquePayments.length / paymentsPerPage);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <Helmet>
        <title>Sales | Jemputlah.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <h1 className="text-3xl font-bold mb-4">Sales</h1>
        <p>Welcome to the Sales!</p>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <FaSpinner className="text-red-400 text-4xl animate-spin" />
          </div>
        ) : (
          <div className='mt-4'>
            {/* Table view for desktop */}
            <div className="hidden md:block">
              <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg overflow-hidden">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-2 text-left text-gray-600 font-semibold border-b border-gray-200">Payment ID</th>
                    <th className="px-4 py-2 text-left text-gray-600 font-semibold border-b border-gray-200">Name</th>
                    <th className="px-4 py-2 text-left text-gray-600 font-semibold border-b border-gray-200">Contact</th>
                    <th className="px-4 py-2 text-left text-gray-600 font-semibold border-b border-gray-200">Kad</th>
                    <th className="px-4 py-2 text-left text-gray-600 font-semibold border-b border-gray-200">Paid At</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPayments.map((payment) => (
                    <tr key={payment.payment_id} className="hover:bg-gray-50">
                      <td className="px-4 py-3 border-b border-gray-200 text-gray-700">{payment.payment_id}</td>
                      <td className="px-4 py-3 border-b border-gray-200 text-gray-700">{payment.name}</td>
                      <td className="px-4 py-3 border-b border-gray-200 text-gray-700 flex items-center space-x-4">
                        <a href={`mailto:${payment.email}`} className="text-blue-500 hover:text-blue-700" title="Email">
                          <FaEnvelope className='text-2xl' />
                        </a>
                        <a href={`https://wa.me/6${payment.mobile}`} className="text-green-500 hover:text-green-700" title="WhatsApp">
                          <FaWhatsapp className='text-2xl' />
                        </a>
                      </td>
                      <td className="px-4 py-3 border-b border-gray-200 text-gray-700">
                        <a 
                          href={`https://jemputlah.com/kad/${payment.client_id}`} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="px-3 py-1 bg-red-400 text-white rounded-md hover:bg-red-500"
                        >
                          View Kad
                        </a>
                      </td>
                      <td className="px-4 py-3 border-b border-gray-200 text-gray-700">{formatDateMalaysia(payment.paid_at)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Card list view for mobile */}
            <div className="block md:hidden space-y-4">
              {currentPayments.map((payment) => (
                <div key={payment.payment_id} className="bg-white border border-gray-200 rounded-lg shadow p-4 flex flex-col space-y-2">
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-700 font-semibold">Payment ID:</span>
                    <span className="text-gray-900">{payment.payment_id}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-700 font-semibold">Name:</span>
                    <span className="text-gray-900">{payment.name}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-700 font-semibold">Contact:</span>
                    <div className="flex items-center space-x-4">
                      <a href={`mailto:${payment.email}`} className="text-blue-500 hover:text-blue-700" title="Email">
                        <FaEnvelope />
                      </a>
                      <a href={`https://wa.me/6${payment.mobile}`} className="text-green-500 hover:text-green-700" title="WhatsApp">
                        <FaWhatsapp />
                      </a>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-700 font-semibold">Kad:</span>
                    <a 
                      href={`https://jemputlah.com/kad/${payment.client_id}`} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="px-3 py-1 bg-red-400 text-white rounded-md hover:bg-red-500"
                    >
                      View Kad
                    </a>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-700 font-semibold">Paid At:</span>
                    <span className="text-gray-900">{formatDateMalaysia(payment.paid_at)}</span>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div className="flex justify-center mt-4">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-4 py-2 bg-gray-200 rounded-md mr-2 disabled:opacity-50"
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-4 py-2 bg-gray-200 rounded-md ml-2 disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sales;
